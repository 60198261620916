'use strict'

const React = require('react')
const _ = require('lodash')
const MeshReact = require('santa-mesh/cjs/src/MeshReact')
const createReactElement = require('../../utils/createReactElement')
const styleRenderer = require('../StyleRenderer/StyleRenderer')

const getGridVariant = browser => browser.ie ? 'ms' : 'standard'
const getChildId = comp => comp.props.id
const getMeshStyles = (id, contentSkinPartId) => stylesMap => styleRenderer({
    id: `${id}-mesh-styles`,
    stylesMap: _.merge(
        stylesMap, {
            [`${id}${contentSkinPartId}`]: {
                position: 'relative'
            },
            [`${id}centeredContent`]: {
                position: 'relative'
            },
            // To make sure overlaps and soap container does not block clicks on components underneath
            [`${id}${contentSkinPartId}-gridWrapper`]: {
                'pointer-events': 'none'
            },
            [`${id}${contentSkinPartId}-gridContainer > *`]: {
                'pointer-events': 'initial'
            }
        }
    )
})

const SantaMesh = ({container, contentArea, adjustingId, browser, fixedChildrenIDs, children, contentSkinPartId = 'inlineContent', filterChildren = () => true}) => {
    const fixedChildrenMap = fixedChildrenIDs.map(id => ({[id]: true})).reduce(_.assign, {})
    const filteredChildrenArray = React.Children.toArray(children).filter(_.flow(getChildId, filterChildren))
    const isReactChildFixed = comp => !!fixedChildrenMap[getChildId(comp)]
    const [fixedChildren, notFixedChildren] = _.partition(filteredChildrenArray, isReactChildFixed)
    const modifiedContainer = Object.assign(
        {},
        container, {
            id: `${container.id}${contentSkinPartId}`,
            components: container.components.filter(comp => !comp.isFixed && filterChildren(comp.id))
        },
        contentArea && {width: '100%', contentArea}
    )

    return [
        ...fixedChildren,
        createReactElement(
            MeshReact.default,
            {
                container: modifiedContainer,
                adjustingId,
                key: `${container.id}-MeshReact`,
                getChildId,
                options: {cssGridVariant: getGridVariant(browser)},
                getStyle: getMeshStyles(container.id, contentSkinPartId)
            },
            notFixedChildren
        )
    ]
}

module.exports = SantaMesh