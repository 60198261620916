'use strict'

const _ = require('lodash')
const skinsJson = require('./skins/skins.json.js')
const createReactClass = require('create-react-class')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')
const skinBasedComp = require('../../mixins/skinBasedComp')

const FiveGridLine = createReactClass({
    displayName: 'FiveGridLine',
    mixins: [skinBasedComp(skinsJson)],
    propTypes: {
        theme: santaTypesDefinitions.Component.theme
    },
    statics: {
        compSpecificIsDomOnlyOverride: () => false,
        getComponentSkins: () => skinsJson
    },
    getSkinProperties() {
        const borderTopWidth = _.get(this.props.theme, 'style.properties.lnw', null)
        const rotationYOffset = borderTopWidth !== null ? `${parseFloat(borderTopWidth) / 2}px` : 'center'
        return {
            '': {
                style: {
                    transformOrigin: `center ${rotationYOffset}`
                }
            }
        }
    }
})

module.exports = FiveGridLine