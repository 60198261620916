'use strict'

const _ = require('lodash')

function getPublicState(state) {
    return {
        shouldShowValidationIndication: _.get(state, 'shouldShowValidationIndication', false)
    }
}

module.exports = {
    validatable: {
        requestSetCustomValidity(customValidity) {
            this.customValidityToSet = customValidity
        },

        componentDidUpdate() {
            if (_.isString(this.customValidityToSet) && _.isFunction(this.setCustomValidity)) {
                this.setCustomValidity(this.customValidityToSet)
                this.customValidityToSet = null
            }
        },

        updateValidityIndication(value) {
            this.setState({shouldShowValidationIndication: value})
        },

        showValidityIndication() {
            this.updateValidityIndication(true)
        },

        hideValidityIndication() {
            this.updateValidityIndication(false)
        },

        shouldShowValidityIndication() {
            return this.state.shouldShowValidationIndication
        }
    },
    getPublicState,
    VALIDATABLE_BEHAVIORS: {
        setCustomValidity: {methodName: 'requestSetCustomValidity', params: ['customValidity']},
        updateValidityIndication: {methodName: 'updateValidityIndication', params: ['value']}
    }
}