'use strict'

const santaTypesDefinitions = require('./definitions/santaTypesDefinitions')
const compStateMixin = require('./mixins/compStateMixin')
const textScaleMixin = require('./mixins/textScaleMixin')
const withHandleAction = require('./HOC/withEventHandlers')
const skinBasedComp = require('./mixins/skinBasedComp')
const inputFocusMixin = require('./mixins/inputFocusMixin')
const runTimeCompData = require('./mixins/runTimeCompData')
const validatableMixin = require('./mixins/validatableMixin')
const validatableWithSyncMixin = require('./mixins/validatableWithSyncMixin')
const createReactElement = require('./utils/createReactElement')

module.exports = {
    HOC: {
        withHandleAction
    },
    mixins: {
        compStateMixin,
        inputFocusMixin,
        runTimeCompData,
        skinBasedComp,
        textScaleMixin,
        validatableMixin,
        validatableWithSyncMixin
    },
    santaTypesDefinitions,
    utils: {
        createReactElement
    }
}
